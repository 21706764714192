import React from 'react';
import Transition from '@components/Transition';
import Title from '@components/Title';
import Blurb from '@components/Blurb';
import BlurbImage from '@components/BlurbImage';
import Text from '@components/Text';
import Browser from '@components/Browser';
import gatkuProductPageImg from '@assets/img/gatku-1.jpg';
import gatkuCartImg from '@assets/img/gatku-3.jpg';
import gatkuCardGif from '@assets/img/gatku-cart.gif';
import gatkuHovercardGif from '@assets/img/gatku-hovercard.gif';
import gatkuHomepage from '@assets/img/gatku-2.jpg';
import { Helmet } from 'react-helmet';

const Gatku = () => (
    <Transition>
        <Helmet>
            <title>GATKU Polespears | Austen Payan</title>
            <link rel="canonical" href="https://austen.io/work/gatku" />
        </Helmet>
        <Title>GATKU Polespears</Title>
        <Blurb
            left={() => (
                <Text>
                    <ul>
                        <li>Lead Developer</li>
                        <li>2014 - 2015</li>
                        <li>San Diego, CA</li>
                    </ul>
                </Text>
            )}
            right={() => (
                <>
                    <Text>It was 2013, and business was booming for GATKU Polespears. While their current website had served them well for years, it was time to make major upgrades to the look, feel, and experience of the brand's digital space.</Text>
                    <br />
                    <Text>The initial redesign + launch in December 2013 was a huge success, and sales in 2014 were their greatest ever. There have been multiple iterations since then, and today GATKU.com is a full featured custom eCommerce application, complete with a content management system, a live updating cart, and more.</Text>
                </>
            )}
        />
        <Browser
            src={gatkuProductPageImg}
        />
        <Browser
            src={gatkuCartImg}
        />
        <Blurb
            left={() => (
                <Text>Dynamic Cart</Text>
            )}
            right={() => (
                <>
                    <Text>In the latest iteration of the site, we moved away from Paypal's purchase button feature to Stripe, which allowed the creation of a dynamic cart where users could checkout all at once without leaving the page.</Text>
                    <BlurbImage src={gatkuCardGif} />
                    <Text>The cart is built with Angular.js and is accessible from any page. Users can adjust the quantity of the products they have placed in their cart, and the price indicators will automatically update. Total and Shipping automatically update as well, and the cart will even tell you in real time when you have added enough items to reach certain shipping price breaks.</Text>
                </>
            )}
            useTopRule={true}
        />
        <Blurb
            left={() => (
                <Text>Product Tooltip Component</Text>
            )}
            right={() => (
                <>
                    <Text>For the product descriptions, I developed an angular directive that highlighted a product name and inserted a tooltip card next to the product name that is activated on hover. This component is a completely re-useable html element. The card allowed us to inform potential buyers of related products in a fun and easy way, reducing the knowledge barrier and providing a boost to sales.</Text>
                    <BlurbImage src={gatkuHovercardGif} />
                </>
            )}
            useTopRule={true}
        />
        <Browser
            src={gatkuHomepage}
        />
        <Blurb
            left={() => (
                <Text>Conclusion</Text>
            )}
            right={() => (
                <>
                    <Text>The GATKU brand has matured into a world-renowned presence in the 'spearo' community, thanks in (large) part to our efforts with gatku.com, and not to mention their amazing products!</Text>
                    <br />
                    <Text>Takeaways from this project included massive gains in experience with Angular.js, Laravel, and the Stripe API. Working closely with a designer throughout, I excersiced clear, concise communication in order to produce a compelling, unforgettable experience.</Text>
                </>
            )}
            useTopRule={true}
        />
    </Transition>
);

export default Gatku;